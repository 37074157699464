import { CloseModalButton, useModal } from "~/components/modal";
import { PhosphorIcon } from "~/widgets/icons";

export const CopyLinkModal = () => {
  const { closeModal } = useModal()!;

  return (
    <>
      {" "}
      <div class="absolute right-2 top-2">
        <CloseModalButton />
      </div>
      <div class="mb-3 flex items-center justify-center gap-1">
        <PhosphorIcon name="check" fontSize={12} class={"text-[#24DD7D]"} />
        <p class="font-jakartaSans text-smallBold uppercase tracking-[1.1px] text-[#24DD7D]">
          link copied to clipboard
        </p>
      </div>
      <p class="pb-5 text-center font-jakartaSans text-h3 text-white">
        Please make sure the <br /> link goes to the right person
      </p>
      <p class="pb-8 text-center text-normal text-textNormal">
        Anyone with this link can use the gift balance for the included
        experiences.
      </p>
      <button
        onClick={closeModal}
        class="flex h-11 w-full items-center justify-center rounded-[41px] bg-baseTertiaryMedium px-3"
      >
        <p class="text-buttonMedium font-bold text-textDark">Got it</p>
      </button>
    </>
  );
};
