import { A, useNavigate } from "@solidjs/router";
import { Accessor, createEffect, createSignal, Show } from "solid-js";
import {
  flipIcon,
  novaChatBubbleBtm,
  plutoGiftBtmRight,
} from "~/assets/assets";
import { GiftCardDetails } from "~/server/types/pluto";
import { getShareableGiftLink } from "~/server/apis/client_apis";
import { ThreeDotLoader } from "~/widgets/button";
import { GiftCardFront } from "./gift_card_front";
import { GiftCardBack } from "./gift_card_back";
import { useModal } from "~/components/modal";
import { showSnackBar } from "~/shared_states/snackbar";
import { CopyLinkModal } from "../modals/copy_link_modal";
import { ShareAppTray } from "../modals/share_app_tray";

export interface GiftCardProps {
  giftDetails: () => GiftCardDetails;
  isEditable?: boolean;
  flipOnCard?: boolean;
  showActions?: boolean;
  showActionsBg?: boolean;
  showHeader?: boolean;
  generatedOnDate?: string;
  budget?: string;
  showCenterHeader?: boolean;
  autoFlip?: boolean;
  onAutoFlipComplete?: () => void;
  showNavigationDots?: boolean;
}

export function GiftCard({
  giftDetails,
  isEditable = false,
  flipOnCard = false,
  showActions = true,
  showActionsBg = true,
  showHeader,
  showCenterHeader,
  generatedOnDate,
  budget,
  autoFlip = false,
  showNavigationDots = false,
  onAutoFlipComplete,
}: GiftCardProps) {
  const navigate = useNavigate();
  const { openModal, closeModal } = useModal()!;

  const [isFlipped, setIsFlipped] = createSignal(false);
  const [shareLink, setShareLink] = createSignal("");
  const [loadingState, setLoadingState] = createSignal({
    copy: false,
    share: false,
  });
  const [hasAutoFlipped, setHasAutoFlipped] = createSignal(false);

  const toggleFlip = () => setIsFlipped(!isFlipped());

  createEffect(async () => {
    if (giftDetails().paymentStatus === "PAID" && !shareLink() && showActions) {
      const response = await getShareableGiftLink(giftDetails().id);
      if (response.link) {
        setShareLink(response.link);
      }
    }
  });

  const getShareableLink = async (action: "share" | "copy") => {
    setLoadingState((prev) => ({ ...prev, [action]: true }));
    try {
      const response = await getShareableGiftLink(giftDetails().id);
      if (response.link) {
        setShareLink(response.link);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingState((prev) => ({ ...prev, [action]: false }));
    }
  };

  const shareNowModal = async (copy?: boolean) => {
    const action = copy ? "copy" : "share";
    if (!shareLink()) {
      await getShareableLink(action);
    }

    const [isCopied, setIsCopied] = createSignal(false);

    if (copy) {
      try {
        navigator.clipboard.writeText(shareLink());
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 2000);
      } catch (error) {
        showSnackBar({
          level: "error",
          message: "Failed to copy link",
        });
      }
    }

    openModal(
      () => {
        const handleShareClick = async () => {
          if (!isCopied()) {
            try {
              navigator.clipboard.writeText(shareLink());
              setIsCopied(true);
              setTimeout(() => setIsCopied(false), 2000);
            } catch (error) {
              showSnackBar({
                level: "error",
                message: "Failed to copy link",
              });
            }
          }
        };

        return (
          <div class="relative w-full p-4">
            <Show when={!copy} fallback={<CopyLinkModal />}>
              <ShareAppTray
                isCopied={isCopied}
                shareLink={shareLink}
                handleShareClick={handleShareClick}
              />
            </Show>
          </div>
        );
      },
      "dark lg:w-[400px]",
      "!bg-black !border-t !border-basePrimaryDark"
    );
  };

  createEffect(() => {
    if (autoFlip && !hasAutoFlipped()) {
      setIsFlipped(false);

      setTimeout(() => {
        setIsFlipped(true);

        setTimeout(() => {
          setHasAutoFlipped(true);
          onAutoFlipComplete?.();
        }, 500);
      }, 1000);
    }
  });

  function buildGiftCardContent() {
    return (
      <GiftCardContent
        giftDetails={giftDetails}
        isEditable={isEditable}
        flipOnCard={flipOnCard}
        isFlipped={isFlipped}
        toggleFlip={toggleFlip}
      />
    );
  }

  const cardWithDots = (
    <>
      <div class="flex flex-col items-center justify-start">
        {buildGiftCardContent()}
        <Show when={showNavigationDots}>{buildFlipControls()}</Show>
      </div>
    </>
  );

  function buildFlipControls() {
    return (
      <>
        <div class="mt-3"></div>
        <div
          class="flex w-full flex-row items-center justify-center gap-1 text-mediumBold text-white"
          onClick={() => {
            toggleFlip();
          }}
        >
          <img src={flipIcon} alt="flip icon" />
          {isFlipped() ? "Front: Wish message" : "Back: The gift"}
        </div>
        <div class="mt-3"></div>
      </>
    );
  }

  return (
    <Show when={showActions} fallback={cardWithDots}>
      <div
        class="mx-auto mb-8 flex w-full max-w-[475px] flex-col items-center justify-start rounded-[40px] p-4 lg:w-fit"
        classList={{ "bg-[#151515]": showActionsBg }}
      >
        <Show when={showHeader}>
          <div class="mb-4 flex items-center gap-2 px-1">
            <p class="text-nowrap text-smallBold uppercase tracking-[1.1px]">
              <Show when={generatedOnDate}>{generatedOnDate}</Show>{" "}
              <Show when={budget}>· Worth {budget}</Show>
            </p>
            <hr class="w-full border border-t border-basePrimaryDark" />
          </div>
        </Show>
        <Show when={showCenterHeader}>
          <div class="mb-2 flex items-center gap-2 px-1">
            <hr class="w-full border border-t border-basePrimaryDark" />
            <p class="text-nowrap text-smallBold uppercase tracking-[1.1px]">
              Created on {generatedOnDate}
            </p>
            <hr class="w-full border border-t border-basePrimaryDark" />
          </div>
        </Show>
        {buildGiftCardContent()}

        <Show when={showNavigationDots}>{buildFlipControls()}</Show>

        <div class="flex w-full gap-3">
          <Show
            when={giftDetails().paymentStatus === "PAID"}
            fallback={
              <button
                onClick={() => {
                  closeModal();
                  navigate(`/pluto/create/${giftDetails().id}/budget`);
                }}
                class="flex h-[44px] w-full items-center justify-center rounded-[41px] bg-baseTertiaryMedium px-3"
              >
                <p class="text-buttonMedium font-bold text-textDark">
                  Next: Load money
                </p>
              </button>
            }
          >
            <button
              onClick={() => shareNowModal(true)}
              class="flex h-[44px] w-[165px] items-center justify-center rounded-[41px] border border-baseSecondaryDark bg-black px-3"
            >
              {loadingState().copy ? (
                <ThreeDotLoader color="#fff" />
              ) : (
                <p class="text-buttonMedium font-bold text-white">Copy link</p>
              )}
            </button>
            <button
              onClick={() => shareNowModal()}
              class="flex h-[44px] w-[165px] items-center justify-center rounded-[41px] bg-baseTertiaryMedium px-3"
            >
              {loadingState().share ? (
                <ThreeDotLoader color="#fff" />
              ) : (
                <p class="text-buttonMedium font-bold text-textDark">
                  Share now
                </p>
              )}
            </button>
          </Show>
        </div>
        <div class="mt-3 flex justify-center">
          <button
            onClick={() => {
              closeModal();
              navigate(`/pluto/create/${giftDetails()?.id}`);
            }}
            class="text-buttonMedium text-white underline"
          >
            View details
          </button>
        </div>
      </div>
    </Show>
  );
}

export function GiftCardContent(props: {
  giftDetails: Accessor<GiftCardDetails>;
  isEditable: boolean;
  flipOnCard: boolean;
  isFlipped: Accessor<boolean>;
  toggleFlip: () => void;
  class?: string;
}) {
  return (
    <div
      classList={{
        [props.class || ""]: !!props.class,
      }}
      class={`relative aspect-[343/541] h-[541px] rounded-3xl transition-transform 
        duration-500 [perspective:1000px]`}
    >
      <div
        class={`relative h-full w-full transition-transform duration-500 
                 [transform-style:preserve-3d] ${props.isFlipped() ? "[transform:rotateY(180deg)]" : ""}`}
      >
        <div
          class="backface-hidden absolute h-full w-full rounded-3xl bg-plutoGiftCard"
          style={{ "backface-visibility": "hidden" }}
        >
          <img
            src={novaChatBubbleBtm}
            class="absolute bottom-0 left-0 rounded-bl-[20px]"
          />
          <img
            src={plutoGiftBtmRight}
            class="absolute bottom-0 right-0 rounded-br-[20px]"
          />
          <GiftCardFront
            giftDetails={props.giftDetails}
            isEditable={props.isEditable}
            onClick={props.flipOnCard ? props.toggleFlip : undefined}
          />
        </div>

        <div
          class="backface-hidden absolute h-full w-full rounded-3xl bg-plutoGiftCard [transform:rotateY(180deg)]"
          style={{ "backface-visibility": "hidden" }}
        >
          <img
            src={novaChatBubbleBtm}
            class="absolute bottom-0 left-0 rounded-bl-[20px]"
          />
          <img
            src={plutoGiftBtmRight}
            class="absolute bottom-0 right-0 rounded-br-[20px]"
          />
          <GiftCardBack
            giftDetails={props.giftDetails}
            isEditable={props.isEditable}
            onClick={props.flipOnCard ? props.toggleFlip : undefined}
          />
        </div>
      </div>
    </div>
  );
}
